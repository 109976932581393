import styled from 'styled-components';
import React from 'react';
import { Button, Checkbox, Icon, Loader, Select, Text, Pagination, } from '@audi/audi-ui-react-v2';
export const LocationFilterContainer = styled.div `
  width: 100%;
  position: relative;
`;
export const LocationFilterHeaderContainer = styled.div `
  width: 100%;
  padding-block-end: var(${(props) => props.theme.responsive.spacing.xl});

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    padding-block-end: var(${(props) => props.theme.responsive.spacing.xxl});
  }
`;
export const LocationFilterBodyContainer = styled.div `
  --height-map-dealerslist-mobile: calc(((100vw - (2*var(${(props) => props.theme.responsive.spacing.l})))/3)*4);
  --height-map-dealerslist-tablet: calc(((100vw - (2*var(${(props) => props.theme.responsive.spacing.l})))/4)*3);
  --height-map: 547px;
  --height-dealerslistheaderfooter: 53px;
  --height-dealerslist: calc(var(--height-map) - 2*var(--height-dealerslistheaderfooter));
  /* this is only part of the gap, the rest of the "visual gap" is added to the dealerListContainer so that the scrollbar is not touching the map */
  --column-gap: 6px;

  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--height-map)
  column-gap: var(--column-gap);
  background-color: transparent;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    grid-template-columns: 1fr 1fr;
    overflow: auto;
    margin-block-end: var(${(props) => props.theme.responsive.spacing.xxl});
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    grid-template-columns: 1fr 2fr;
  }
`;
export const LocationInputContainer = styled.div `
  width: 100%;
  display: grid;
  align-items: end;
  grid-template-columns: auto;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    grid-template-columns: 45% 25%;
    grid-column-gap: 5%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin-top: var(${(props) => props.theme.responsive.spacing.l});
    height: 57px;
    grid-template-columns: auto auto auto;
    margin-top: unset;
    grid-column-gap: unset;
  }
`;
export const SearchWrapper = styled.div `
  display: grid;
  width: 100%;
  grid-template-columns: 93% auto;

  label {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;
export const IconCancelWrapper = styled.div `
  position: absolute;
  right: 0;
  > * {
    &:before {
      color: var(
        ${(props) => props.disabled && props.theme.colors.interaction.label.disabled}
      ) !important;
    }
  }
  pointer-events: all;
`;
export const DealerNameAndCount = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const StyledSelect = styled((props) => React.createElement(Select, Object.assign({}, props))) `
  margin-top: var(${(props) => props.theme.responsive.spacing.l});
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    margin-top: none;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin-inline-start: 36px;
  }
`;
export const StyledCheckboxAllDealersChosen = styled((props) => (React.createElement(Checkbox, Object.assign({}, props)))) `
  padding-block-start: var(${(props) => props.theme.responsive.spacing.l});
  padding-inline-start: var(${(props) => props.theme.responsive.spacing.s});
  border-block-start: 1px solid var(${(props) => props.theme.colors.ui.divider});

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    padding-block-start: var(${(props) => props.theme.responsive.spacing.m});
    padding-block-end: var(${(props) => props.theme.responsive.spacing.s});
    margin-inline-end: 32px;
  }
`;
export const LocateMeButton = styled((props) => React.createElement(Button, Object.assign({}, props))) `
  min-width: 24px;
  max-width: 36px;
  min-height: 24px;
  max-height: 36px;
  :disabled {
    box-shadow: none;
  }

  /* locate-me icon and delete text */
  svg {
    flex: 1 0 24px;
  }
`;
export const ClearAllLocationsButton = styled((props) => React.createElement(Button, Object.assign({}, props))) `
  margin-top: var(${(props) => props.theme.responsive.spacing.l});
  justify-content: start;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    justify-content: end;
    margin-top: unset;
  }
`;
export const MapBlockedContainer = styled.div `
  background-color: var(${(props) => props.theme.colors.background.backdrop.strong});
  min-height: var(--height-map-dealerslist-mobile);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    min-height: var(--height-map-dealerslist-tablet);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    min-height: var(--height-map);
    margin-inline-start: 24px;
  }
`;
export const MapConsentRequestButton = styled((props) => React.createElement(Button, Object.assign({}, props))) `
  width: auto;
`;
export const StyledEyeCrossedIcon = styled((props) => React.createElement(Icon, Object.assign({}, props))) `
  color: var(
    ${(props) => props.lightMode ? props.theme.colors.ui.inverted : props.theme.colors.ui.secondary}
  );
`;
export const StyledApprovalText = styled((props) => (React.createElement(Text, Object.assign({ as: "p", variant: "copy1" }, props)))) `
  color: var(
    ${(props) => props.lightMode ? props.theme.colors.ui.inverted : props.theme.colors.ui.secondary}
  );
`;
export const ConsentRequestWrapperMobile = styled.div `
  padding: 0 0 var(${(props) => props.theme.responsive.spacing.xxl});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    padding: 0 0 var(${(props) => props.theme.responsive.spacing.xl});
  }
`;
export const CookieConsentRequestContainer = styled.div `
  display: flex;
  align-items: flex-start;
  padding: var(${(props) => props.theme.responsive.spacing.m});
  gap: var(${(props) => props.theme.responsive.spacing.s});
  color: var(${(props) => props.theme.colors.ui.primary});
  background-color: var(${(props) => props.theme.colors.ui.highlight.tertiary});
  height: fit-content;

  /* the i-icon */
  svg {
    flex: 1 0 24px;
  }
`;
export const CookieConsentRequestContent = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(
    ${(props) => props.isMas ? props.theme.responsive.spacing.s : props.theme.responsive.spacing.m}
  );
`;
export const CookieConsentControlContainer = styled.div `
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: var(${(props) => props.theme.responsive.spacing.m});
  margin-block-start: var(${(props) => props.theme.responsive.spacing.xxl});
  margin-inline-end: var(${(props) => props.theme.responsive.spacing.l});
  gap: var(${(props) => props.theme.responsive.spacing.s});
  color: var(${(props) => props.theme.colors.ui.primary});
  background-color: var(${(props) => props.theme.colors.ui.highlight.tertiary});
  height: fit-content;
  box-sizing: border-box;

  svg {
    flex: 1 0 24px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin-block-start: unset;
  }
`;
export const DealersListContainer = styled.div `
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  position: ${(props) => (props.visible ? 'static' : 'absolute')};
  left: ${(props) => (props.visible ? 'unset' : '1000%')};

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: flex;
    flex-direction: column;
  }
`;
export const DealersListHeaderWrapper = styled.div `
  padding-block-start: var(${(props) => props.theme.responsive.spacing.m});
  padding-block-end: var(${(props) => props.theme.responsive.spacing.s});
  display: flex;
  border-block-end: 1px solid var(${(props) => props.theme.colors.ui.divider});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    padding-block-end: var(${(props) => props.theme.responsive.spacing.m});
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    /* The 32px margin is needed to have enough space for the scroll bar not to touch neither the divider lines nor the map */
    margin-inline-end: 32px;
    justify-content: space-between;
    padding-block-start: var(${(props) => props.theme.responsive.spacing.s});
  }
`;
export const DealersListWrapper = styled.div `
  height: var(--height-map-dealerslist-mobile);

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    height: var(--height-map-dealerslist-tablet);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    overflow: auto;
    height: var(--height-dealerslist);
    /* margin needed to create space between scrollbar and map */
    margin-inline-end: 6px;

    ${(props) => props.zeroResults && 'height:0; flex-grow: 1;'};
  }
`;
export const StyledAccordion = styled.div `
  min-height: 250px;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    min-height: 500px;
  }
`;
export const DealersListAccordionWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
export const StyledPagination = styled((props) => React.createElement(Pagination, Object.assign({}, props))) `
  align-self: center;
  margin-top: var(${(props) => props.theme.responsive.spacing.l});
  margin-bottom: var(${(props) => props.theme.responsive.spacing.m});
`;
export const RenderedDealersList = styled.div `
  height: calc(var(--height-map-dealerslist-mobile) - 66px);
  overflow: auto;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    height: calc(var(--height-map-dealerslist-tablet) - 66px);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    height: var(--height-dealerslist);
    padding-inline-end: var(${(props) => props.theme.responsive.spacing.l});
    ${(props) => props.zeroResults && 'height:0;'};
  }
`;
export const DealersFoundContainer = styled.div `
  display: flex;
  flex-direction: row;
`;
export const DealersSelectedContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const StyledIcon = styled((props) => React.createElement(Icon, Object.assign({}, props))) `
  margin-left: var(${(props) => props.theme.responsive.spacing.m});
  margin-right: var(${(props) => props.theme.responsive.spacing.xxs});

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin-left: unset;
    margin-right: unset;
  }
`;
export const StyledLoader = styled((props) => React.createElement(Loader, Object.assign({}, props))) `
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  z-index: 1;
`;
export const StyledText = styled((props) => React.createElement(Text, Object.assign({ as: "p", variant: "copy2" }, props))) `
  max-width: 25%;
`;
export const StyledCountText = styled((props) => (React.createElement(Text, Object.assign({ as: "p", variant: "copy1", spaceInlineStart: "s", spaceInlineEnd: "s", spaceStackStart: "m" }, props)))) `
  color: var(${(props) => props.disabled && props.theme.colors.interaction.label.disabled});
  align-self: self-start;
  margin-block-start: var(${(props) => props.theme.responsive.spacing.m});
  flex-basis: max-content;
  flex-shrink: 0;
`;
export const StyledCountSpan = styled.span `
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: inline;
  }
`;
export const StyledAddressText = styled((props) => (React.createElement(Text, Object.assign({ as: "p", variant: "copy1" }, props)))) `
  color: var(${(props) => props.disabled && props.theme.colors.interaction.label.disabled});
`;
export const StyledAddressTextNextToIcon = styled((props) => (React.createElement(Text, Object.assign({ as: "p", variant: "copy1" }, props)))) `
  grid-area: street;
  color: var(${(props) => props.disabled && props.theme.colors.interaction.label.disabled});
`;
export const StyledAddressTextFullWidth = styled((props) => (React.createElement(Text, Object.assign({ as: "p", variant: "copy1" }, props)))) `
  grid-area: city;
  color: var(${(props) => props.disabled && props.theme.colors.interaction.label.disabled});
`;
export const StyledDistanceText = styled((props) => (React.createElement(Text, Object.assign({ as: "p", variant: "copy1" }, props)))) `
  color: var(${(props) => props.disabled && props.theme.colors.interaction.label.disabled});
`;
export const StyledAddressIcon = styled((props) => (React.createElement(Icon, Object.assign({ name: "poi", size: "small", variant: "primary", spaceInlineEnd: "xs" }, props)))) `
  color: var(
    ${(props) => props.disabled && props.theme.colors.interaction.label.disabled}
  ) !important;

  > :before {
    color: var(
      ${(props) => props.disabled && props.theme.colors.interaction.label.disabled}
    ) !important;
  }
`;
export const StyledDistanceIcon = styled((props) => (React.createElement(Icon, Object.assign({ name: "distance", size: "small", variant: "primary", spaceInlineEnd: "xs" }, props)))) `
  color: var(
    ${(props) => props.disabled && props.theme.colors.interaction.label.disabled}
  ) !important;

  > :before {
    color: var(
      ${(props) => props.disabled && props.theme.colors.interaction.label.disabled}
    ) !important;
  }
`;
export const DealersListRef = styled.div `
  :not(:last-child) {
    border-block-end: 1px solid var(${(props) => props.theme.colors.ui.divider});
  }
`;
export const DealerListItemWrapper = styled.div `
  padding-block-end: var(${(props) => props.theme.responsive.spacing.l});
  background-color: var(
    ${(props) => props.status === 'focused' && props.lightMode
    ? props.theme.colors.base.grey[5]
    : props.status === 'focused'
        ? props.theme.colors.base.brand.black
        : 'transparent'}
  );
`;
export const DealerDetails = styled.div `
  padding-left: 44px;
  pointer-events: none;

  :hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;
export const Address = styled.div `
  display: flex;
  flex-wrap: wrap;
  pointer-events: none;
  padding-inline-end: var(${(props) => props.theme.responsive.spacing.s});
`;
export const AddressMobile = styled.div `
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'addressicon street street'
    '. city city';
`;
export const Distance = styled.div `
  display: flex;
  pointer-events: none;
  padding-block-start: var(${(props) => props.theme.responsive.spacing.s});
  padding-inline-end: var(${(props) => props.theme.responsive.spacing.s});
`;
export const Map = styled.div `
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  position: ${(props) => (props.visible ? 'relative' : 'absolute')};
  left: ${(props) => (props.visible ? 'unset' : '1000%')};
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: var(--height-map-dealerslist-mobile);
  max-height: var(--height-map-dealerslist-mobile);

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    min-height: var(--height-map-dealerslist-tablet);
    max-height: var(--height-map-dealerslist-tablet);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    min-height: var(--height-map);
    max-height: var(--height-map);
  }

  &:after {
    display: ${(props) => (props.zeroResultsFound ? 'block' : 'none')};
    content: '';
    background: #000000;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .checked,
  .unchecked,
  .focused {
    pointer-events: all !important;
  }

  .disabled {
    pointer-events: none;
  }
`;
export const FocusedDealerWrapper = styled.div `
  position: absolute;
  width: 100%;
  bottom: 0;
`;
export const ZeroResultsBannerWrapper = styled.div `
  z-index: 2;
  position: ${(props) => (props.isDisplayedOnMapTab ? 'absolute' : 'static')};
  width: calc(100% - (2 * var(${(props) => props.theme.responsive.spacing.l})));
  top: 0;
  background-color: var(
    ${(props) => props.isDisplayedOnMapTab && props.lightMode
    ? props.theme.colors.base.brand.white
    : props.isDisplayedOnMapTab
        ? props.theme.colors.base.brand.black
        : 'unset'}
  );
  padding-top: var(
    ${(props) => props.isDisplayedOnMapTab
    ? props.theme.responsive.spacing.l
    : props.theme.responsive.spacing.xxl}
  );
  padding-left: var(
    ${(props) => props.isDisplayedOnMapTab
    ? props.theme.responsive.spacing.l
    : props.theme.responsive.spacing.m}
  );
  padding-right: var(
    ${(props) => props.isDisplayedOnMapTab
    ? props.theme.responsive.spacing.l
    : props.theme.responsive.spacing.m}
  );
  padding-bottom: var(
    ${(props) => (props.isDisplayedOnMapTab ? props.theme.responsive.spacing.l : 'unset')}
  );

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    padding: var(${(props) => props.theme.responsive.spacing.xxl})
      var(${(props) => props.theme.responsive.spacing.l});
  }
`;
