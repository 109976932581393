import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ExpiringStorage, SELECTORS } from '@oneaudi/stck-store';
import { areFilterArraysEqualish } from '../utils/utils';
import { Button, Tag, TagGroup, } from '@audi/audi-ui-react-v2';
import { useServicesContext } from '../../../context';
import { useScsUrlParts } from '../../../hooks';
import { trackRemoveAllFilters } from '../tracking';
import { useApplyFilters, useClearAllFilters } from '../FilterContextHooks';
import { findCategoryInFilterConfig } from '../utils/filterHelper';
import { FilterContext } from '../FilterContext';
import { useI18n } from '@oneaudi/i18n-context';
import styled from 'styled-components';
export const ChipsList = ({ configuredFilters, isPlpQuickFilters }) => {
    const clearAllFilters = useClearAllFilters();
    const applyFilters = useApplyFilters();
    const context = React.useContext(FilterContext);
    const localeService = useServicesContext().getAdditionalService('gfa:locale-service');
    const marketLanguage = localeService.language;
    const contentService = useServicesContext().getAdditionalService('audi-content-service');
    let { mileageUnit, lightMode } = contentService.getContent().fields;
    const mileageUn = mileageUnit || 'km';
    const { isPlpFlag } = context;
    if (isPlpFlag) {
        lightMode = true;
    }
    const i18nService = useServicesContext().getAdditionalService('dbad:audi-i18n-service');
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const { isUsedCarMkt, marketPath } = useScsUrlParts();
    const localStorageLocationSearchInputKey = `VTP_${marketPath}_locationSearch--input`;
    const totalResults = useSelector((state) => SELECTORS.VEHICLES.getTotalCountState(state));
    const allSelectedFilters = useSelector((state) => SELECTORS.FILTERS.getSelectedFiltersMap(state));
    const allI18n = (i18nService === null || i18nService === void 0 ? void 0 : i18nService.getMessages()[marketLanguage])
        ? i18nService === null || i18nService === void 0 ? void 0 : i18nService.getMessages()[marketLanguage]
        : i18nService === null || i18nService === void 0 ? void 0 : i18nService.getMessages().default;
    const getI18n = ({ id, defaultMessage = '', }) => {
        return allI18n[id] || defaultMessage || undefined;
    };
    const deleteFiltersLabel = useI18n({
        id: `nemo.ui.sc.filter-options.reset.label`,
        defaultMessage: 'Reset Filters',
    });
    const showMoreFilterLabel = useI18n({
        id: `nemo.ui.sc.filter-options.more.label`,
        defaultMessage: 'more Filter',
    });
    const showFewerFilterLabel = useI18n({
        id: `nemo.ui.sc.filter-options.less.label`,
        defaultMessage: 'Show less',
    });
    const chosenFiltersLabel = useI18n({
        id: `nemo.sc.filter.chosenFilters`,
        defaultMessage: 'Selected Filters',
    });
    const dealerLabelPrefix = useI18n({
        id: `nemo.ui.sc.details.dealer-label`,
        defaultMessage: '',
    });
    const locationChipLabelPrefix = useI18n({
        id: `nemo.sc.filter.locationChipLabelPrefix`,
        defaultMessage: 'Location',
    });
    const filterOptionsFromStore = useSelector((state) => {
        if (!SELECTORS.UI.getLoadingState(state)) {
            return SELECTORS.FILTERS.getFilterOptionsState(state);
        }
        return;
    });
    const getAllFilterDTOFromRedux = useSelector((state) => SELECTORS.FILTERS.getFilterDTOsMap(state));
    const [allChips, setAllChips] = useState([]);
    const [showAllChips, setShowAllChips] = useState(false);
    const maxNumberOfChipsMobile = 4;
    const maxNumberOfChipsDesktop = 8;
    let maxNumberOfChipsToDisplay = window.innerWidth < 768 ? maxNumberOfChipsMobile : maxNumberOfChipsDesktop;
    if (isPlpQuickFilters) {
        maxNumberOfChipsToDisplay =
            window.innerWidth < 768 ? maxNumberOfChipsMobile : window.innerWidth < 1024 ? 8 : 4;
    }
    const numberOfAdditionalChips = allChips.length - maxNumberOfChipsToDisplay;
    const hasPlaceholderLabel = (cleanId) => {
        const genericPlaceholderLabel = getI18n({
            id: `nemo.sc.filtergroups.${cleanId}.rangepattern`,
        });
        const mileageUniPlaceholderLabel = getI18n({
            id: `nemo.sc.filtergroups.${cleanId}.${mileageUn}.rangepattern`,
        });
        if (genericPlaceholderLabel === null || genericPlaceholderLabel === void 0 ? void 0 : genericPlaceholderLabel.includes('{{=it.minmax1}}')) {
            return genericPlaceholderLabel;
        }
        if (mileageUniPlaceholderLabel === null || mileageUniPlaceholderLabel === void 0 ? void 0 : mileageUniPlaceholderLabel.includes('{{=it.minmax1}}')) {
            return mileageUniPlaceholderLabel;
        }
        return false;
    };
    const getAllSelectedChipsLabel = (id) => {
        var _a;
        const allSelectedPairsString = getI18n({
            id: `nemo.sc.filteroptions.allselected-pairs`,
            defaultMessage: 'exterior colour:All exterior colours|interior-colour-seats:All seat colours|material:All materials|etron:e-tron|etrongt:e-tron GT|a1:A1|a3:A3|a4:A4|a5:A5|a6:A6|a7:A7|a8:A8|q2:Q2|q3:Q3|q4:Q4 e-tron|q5:Q5|q7:Q7|q8:Q8|q8etron:Q8 e-tron|tt:TT|r8:R8|etron:e-tron|etrongt:e-tron GT',
        });
        const allSelectedPairs = {};
        for (const element of allSelectedPairsString.split('|')) {
            const pair = element.split(':');
            allSelectedPairs[pair[0]] = (_a = pair[1]) === null || _a === void 0 ? void 0 : _a.trim();
        }
        return allSelectedPairs[id] || undefined;
    };
    const makeMinMaxValueLabelPart = (filterDTO, cleanId) => {
        const { values, display } = filterDTO;
        const minValue = values === null || values === void 0 ? void 0 : values[0];
        const maxValue = values === null || values === void 0 ? void 0 : values[1];
        const minValueDisplay = (display === null || display === void 0 ? void 0 : display[0]) || minValue || '';
        const maxValueDisplay = (display === null || display === void 0 ? void 0 : display[1]) || maxValue || '';
        const rangeMinLabel = getI18n({
            id: `nemo.sc.filtergroups.${cleanId}.rangeMinLabel`,
            defaultMessage: 'from',
        });
        const rangeMaxLabel = getI18n({
            id: `nemo.sc.filtergroups.${cleanId}.rangeMaxLabel`,
            defaultMessage: 'to',
        });
        let valueLabelPart;
        if (minValue && !maxValue) {
            valueLabelPart = `${rangeMinLabel} ${minValueDisplay}`;
            if (hasPlaceholderLabel(cleanId)) {
                valueLabelPart = hasPlaceholderLabel(cleanId);
                valueLabelPart = `${rangeMinLabel} ${valueLabelPart
                    .replace('{{=it.minmax1}}', minValueDisplay)
                    .replace('{{=it.minmax2}}', (display === null || display === void 0 ? void 0 : display[2]) || '')}`;
            }
        }
        else {
            valueLabelPart = `${rangeMaxLabel} ${maxValueDisplay}`;
            if (hasPlaceholderLabel(cleanId)) {
                valueLabelPart = hasPlaceholderLabel(cleanId);
                valueLabelPart = `${rangeMaxLabel} ${valueLabelPart
                    .replace('{{=it.minmax1}}', maxValueDisplay)
                    .replace('{{=it.minmax2}}', (display === null || display === void 0 ? void 0 : display[3]) || '')}`;
            }
        }
        return valueLabelPart;
    };
    const getRangeValueLabel = (filterChipDTO) => {
        if (!filterChipDTO) {
            throw new Error('Missing param filterDTO in getRangeValueLabel!');
        }
        const cleanId = filterChipDTO.id.split('.all')[0].split(':min')[0].split(':max')[0];
        const labelValuePart = makeMinMaxValueLabelPart(filterChipDTO, cleanId);
        const specificI18nLabel = getI18n({
            id: `nemo.sc.filtergroups.${cleanId}`,
        });
        const labelFilternamePart = specificI18nLabel || filterChipDTO.id;
        return `${labelFilternamePart} ${labelValuePart}`;
    };
    const removeDuplicatedChips = (filterChipsDTOArray) => {
        const uniqueFilterChipsArray = [];
        const uniqueIdsArray = [];
        filterChipsDTOArray.forEach((filter) => {
            const uniqueId = filter.id === 'carlinegroups' ? filter.id + filter.label : filter.id;
            if (uniqueIdsArray.indexOf(uniqueId) === -1) {
                uniqueFilterChipsArray.push(filter);
                uniqueIdsArray.push(uniqueId);
            }
        });
        return uniqueFilterChipsArray;
    };
    const getAllSelectedCarlineGroupsChipsLabel = (groupId) => {
        return getAllSelectedChipsLabel(groupId) || groupId;
    };
    const generateFilterChipItemForEachFilterGroup = (filterGroup, groupId) => {
        const filterChipDTOsFromGroup = [];
        const selectedFiltersArray = filterGroup.selectedFilter || [];
        const hasAllSelectedChipsLabel = filterGroup.group === 'carlinegroups'
            ? getAllSelectedCarlineGroupsChipsLabel(groupId)
            : getAllSelectedChipsLabel(filterGroup.group);
        if (filterGroup.allSelected && hasAllSelectedChipsLabel) {
            let idsToRemove = [];
            selectedFiltersArray.forEach((filter) => {
                if (filterGroup.group === 'carlinegroups') {
                    idsToRemove = filter.remove;
                }
                else {
                    idsToRemove = [...idsToRemove, filter.remove[0]];
                }
            });
            const allSelectedFilterChip = {
                id: filterGroup.group,
                filterOptionGroupName: filterGroup.group,
                filterOptionGroupText: filterGroup.text,
                group: filterGroup.group,
                label: hasAllSelectedChipsLabel,
                remove: idsToRemove,
                allSelected: true,
            };
            filterChipDTOsFromGroup.push(allSelectedFilterChip);
        }
        else {
            selectedFiltersArray.forEach((filter) => {
                var _a, _b, _c, _d, _e;
                const filterDTO = getAllFilterDTOFromRedux.get(filter.id);
                const filterLabel = filter.text || filter.id || '';
                if (!filterDTO || filterGroup.group === 'bodytypes') {
                    return;
                }
                switch (filterDTO.type) {
                    case 'range-filter':
                        const rangeFilterChip = {
                            id: filterDTO.id,
                            filterOptionGroupName: filterGroup.group,
                            filterOptionGroupText: filterGroup.text,
                            group: filterDTO.group,
                            display: filter.display || undefined,
                            remove: [filterDTO.id],
                            values: filterDTO.values,
                            label: '',
                        };
                        const newRangeLabel = getRangeValueLabel(rangeFilterChip);
                        rangeFilterChip.label = newRangeLabel;
                        filterChipDTOsFromGroup.push(rangeFilterChip);
                        break;
                    case 'minmax-range-filter':
                        const minRangeFilterChip = {
                            id: `${filterDTO.id}:min`,
                            filterOptionGroupName: filterGroup.group,
                            filterOptionGroupText: filterGroup.text,
                            display: filter.display || undefined,
                            active: filterDTO.active,
                            preset: filterDTO.preset,
                            values: [(_a = filterDTO === null || filterDTO === void 0 ? void 0 : filterDTO.values) === null || _a === void 0 ? void 0 : _a[0], ''],
                            removeArr: [filterDTO.id],
                            group: filterDTO.group,
                            label: '',
                        };
                        minRangeFilterChip.label = getRangeValueLabel(minRangeFilterChip);
                        const maxRangeFilterChip = {
                            id: `${filterDTO.id}:max`,
                            filterOptionGroupName: filterGroup.group,
                            filterOptionGroupText: filterGroup.text,
                            display: filter.display || undefined,
                            active: filterDTO.active,
                            preset: filterDTO.preset,
                            values: ['', (_b = filterDTO === null || filterDTO === void 0 ? void 0 : filterDTO.values) === null || _b === void 0 ? void 0 : _b[1]],
                            removeArr: [filterDTO.id],
                            group: filterDTO.group,
                            label: '',
                        };
                        maxRangeFilterChip.label = getRangeValueLabel(maxRangeFilterChip);
                        filterChipDTOsFromGroup.push(minRangeFilterChip, maxRangeFilterChip);
                        break;
                    case 'location-filter':
                        const city = (_c = filterDTO === null || filterDTO === void 0 ? void 0 : filterDTO.values) === null || _c === void 0 ? void 0 : _c[4];
                        const unit = (_d = filterDTO === null || filterDTO === void 0 ? void 0 : filterDTO.values) === null || _d === void 0 ? void 0 : _d[3];
                        const radius = (_e = filterDTO === null || filterDTO === void 0 ? void 0 : filterDTO.values) === null || _e === void 0 ? void 0 : _e[2];
                        const geoFilterLabel = `${locationChipLabelPrefix}: ${city}, ${radius}${unit}`;
                        const locationFilterChip = {
                            id: filterDTO.id,
                            filterOptionGroupName: filterGroup.group,
                            filterOptionGroupText: filterGroup.text,
                            group: filterDTO.group,
                            label: geoFilterLabel,
                            remove: [filterDTO.id],
                        };
                        filterChipDTOsFromGroup.push(locationFilterChip);
                        break;
                    case 'basic-filter':
                    default:
                        let label = filterLabel;
                        if (filterGroup.text && !filter.uniqueText) {
                            label = `${filterGroup.text}: ${filterLabel}`;
                        }
                        if (filterGroup.group === 'dealer') {
                            label = dealerLabelPrefix
                                ? `${dealerLabelPrefix} ${label}`
                                : `${dealerLabelPrefix}${label}`;
                        }
                        const basicFilterChip = {
                            id: filterDTO.id,
                            filterOptionGroupName: filterGroup.group,
                            filterOptionGroupText: filterGroup.text,
                            group: filterDTO.group,
                            label,
                            remove: [filterDTO.id],
                        };
                        filterChipDTOsFromGroup.push(basicFilterChip);
                        break;
                }
            });
        }
        return filterChipDTOsFromGroup;
    };
    const isMinMaxRangePseudoId = (chipFilterId) => {
        return !!(chipFilterId.split(':').length === 2);
    };
    const convertFilterOptionsFromStoreToChipsItems = () => {
        let filterChipsDTOArray = [];
        Object.entries(filterOptionsFromStore).forEach(([groupId, group]) => {
            const itemsArray = generateFilterChipItemForEachFilterGroup(group, groupId);
            filterChipsDTOArray = [...filterChipsDTOArray, ...itemsArray];
        });
        const uniqueFilterOptionsArray = removeDuplicatedChips(filterChipsDTOArray);
        return uniqueFilterOptionsArray;
    };
    const handleChips = () => {
        const newChips = convertFilterOptionsFromStoreToChipsItems();
        if (newChips && !areFilterArraysEqualish(allChips, newChips)) {
            setAllChips(newChips);
        }
    };
    useEffect(() => {
        if (filterOptionsFromStore) {
            handleChips();
        }
    }, [filterOptionsFromStore]);
    const handleRemove = (chip) => {
        var _a, _b;
        const trackingEventName = (chip === null || chip === void 0 ? void 0 : chip.filterOptionGroupName) === 'ranges' && (chip === null || chip === void 0 ? void 0 : chip.display[0]) !== '' && (chip === null || chip === void 0 ? void 0 : chip.display[1]) !== ''
            ? 'vtp filter - change filter'
            : 'vtp filter - remove filter';
        const trackingLabel = chip === null || chip === void 0 ? void 0 : chip.label;
        const filterCategory = (_b = (_a = configuredFilters === null || configuredFilters === void 0 ? void 0 : configuredFilters.filter((configuredFilter) => {
            if (chip.group !== 'carlinegroups') {
                return (findCategoryInFilterConfig(configuredFilter, chip.id) ||
                    findCategoryInFilterConfig(configuredFilter, chip.group) ||
                    findCategoryInFilterConfig(configuredFilter, chip.filterOptionGroupName));
            }
            return findCategoryInFilterConfig(configuredFilter, 'carline');
        })) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.filterCategory;
        let currentFilterForTracking;
        if (chip.allSelected) {
            const filtersToRemove = [];
            chip.remove.forEach((filterId) => {
                const individualFilterToRemove = {
                    id: filterId,
                    active: false,
                };
                filtersToRemove.push(individualFilterToRemove);
            });
            currentFilterForTracking = { id: chip.label.toLowerCase(), values: chip.values || [] };
            const trackingData = {
                trackingEventName,
                trackingLabel,
                trackingValue: filterCategory,
                changedFilter: [currentFilterForTracking],
            };
            applyFilters(filtersToRemove, trackingData);
        }
        else {
            const chipToRemove = {
                id: chip.id,
                active: false,
            };
            if (isMinMaxRangePseudoId(chip.id)) {
                const cleanId = chip.id.split(':')[0];
                const minOrMax = chip.id.split(':')[1];
                chipToRemove.id = cleanId;
                const prevFilter = getAllFilterDTOFromRedux.get(cleanId);
                if (minOrMax === 'min') {
                    chipToRemove.active = true;
                    chipToRemove.values = ['', prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.values[1]];
                }
                if (minOrMax === 'max') {
                    chipToRemove.active = true;
                    chipToRemove.values = [prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.values[0], ''];
                }
            }
            currentFilterForTracking = { id: chip.id, values: chip.values || [] };
            const trackingData = {
                trackingEventName,
                trackingLabel,
                trackingValue: filterCategory,
                changedFilter: [currentFilterForTracking],
            };
            applyFilters(chipToRemove, trackingData);
            if ((chip === null || chip === void 0 ? void 0 : chip.id) === 'geo') {
                context.handleClearLocationInputAndRadiusButKeepSelectedDealers();
                const localStorage = new ExpiringStorage(localStorageLocationSearchInputKey, 30);
                localStorage.clearStorage();
            }
        }
    };
    const handleShowMoreChips = () => {
        setShowAllChips(true);
    };
    const handleShowFewerChips = () => {
        setShowAllChips(false);
    };
    const handleClick = () => {
        const trackingRelatedFilters = [];
        [...allSelectedFilters.values()].forEach((filter) => {
            const relatedFilter = { id: filter.id, values: filter.values || [] };
            trackingRelatedFilters.push(relatedFilter);
        });
        trackRemoveAllFilters(trackingService, 'text link', deleteFiltersLabel, trackingRelatedFilters, totalResults, isUsedCarMkt);
        clearAllFilters();
    };
    if (allChips.length === 0) {
        return null;
    }
    return (React.createElement(StyledTagGroup, { role: "list", "aria-label": chosenFiltersLabel, spaceStackStart: isPlpQuickFilters ? 's' : 'm', spaceStackEnd: "xxxl" },
        allChips.map((chip, index) => {
            if (index < maxNumberOfChipsToDisplay || showAllChips) {
                return (React.createElement(StyledTag, { label: chip.label, role: "listitem", onRemove: () => {
                        handleRemove(chip);
                    }, key: chip.id + index, size: "large", removable: true, lightMode: lightMode }));
            }
            return null;
        }),
        React.createElement(FilterChipsFooter, { isPlpQuickFilters: isPlpQuickFilters },
            numberOfAdditionalChips > 0 && !showAllChips && (React.createElement("span", { role: "listitem" },
                React.createElement(StyledButton, { variant: "text", icon: isPlpQuickFilters ? 'add' : 'system-add', size: "small", onClick: handleShowMoreChips, lightMode: lightMode },
                    numberOfAdditionalChips,
                    " ",
                    showMoreFilterLabel))),
            numberOfAdditionalChips > 0 && showAllChips && (React.createElement("span", { role: "listitem" },
                React.createElement(StyledButton, { variant: "text", icon: isPlpQuickFilters ? 'remove' : 'system-remove', size: "small", onClick: handleShowFewerChips, lightMode: lightMode }, showFewerFilterLabel))),
            React.createElement("span", { role: "listitem" },
                React.createElement(StyledButton, { variant: "text", icon: "erase", size: "small", onClick: handleClick, lightMode: lightMode, isPlpQuickFilters: isPlpQuickFilters }, deleteFiltersLabel)))));
};
const StyledTagGroup = styled((props) => React.createElement(TagGroup, Object.assign({}, props))) `
  align-items: center;
`;
const FilterChipsFooter = styled.div `
  display: ${(props) => (props.isPlpQuickFilters ? 'flex' : 'contents')};
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    width: ${(props) => (props.isPlpQuickFilters ? '100%' : 'unset')};
    justify-content: space-between;
  }
`;
const StyledTag = styled((props) => React.createElement(Tag, Object.assign({}, props))) `
  background-color: ${(props) => props.lightMode ? props.theme.colors.base.grey[5] : props.theme.colors.ui.highlight.tertiary};
  color: ${(props) => props.lightMode ? props.theme.colors.base.brand.black : props.theme.colors.ui.secondary};

  > button {
    color: ${(props) => props.lightMode
    ? props.theme.colors.base.grey[60]
    : props.theme.colors.interaction.secondary.default};
  }
  > button:hover {
    color: var(${(props) => (props.lightMode ? props.theme.colors.base.brand.white : 'unset')});
  }
`;
const StyledButton = styled((props) => React.createElement(Button, Object.assign({}, props))) `
  color: ${(props) => props.lightMode ? props.theme.colors.base.brand.black : props.theme.colors.base.brand.white};
  margin-left: ${(props) => (props.isPlpQuickFilters ? '24px' : 'unset')};

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin-left: unset;
  }
`;
