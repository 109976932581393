var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { FilterContainer } from '../styles/Filter.styles';
import { useScsUrlParts, useSvd, getScsUrlPartsFromConfiguration } from '../../../hooks';
import { useServicesContext } from '../../../context';
import { formatCount } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import ReactDOM from 'react-dom';
import { ACTIONS, SELECTORS, ExpiringStorage } from '@oneaudi/stck-store';
import { areArraysEqual } from '../utils/utils';
import FilterOverlay from './FilterOverlay';
import FilterNavigation from './FilterNavigation';
import { ChipsList } from './ChipsList';
import { ThemeProvider, audiDarkTheme, audiLightTheme } from '@audi/audi-ui-react-v2';
import OptionsToolbar from './OptionsToolbar';
import { trackFeatureAppReady } from '../tracking';
import { createFiltersDataString, createPresetsDataString, updateFiltersAndResults, } from '../filterService';
import { updateFilters, useFilterData } from '../FilterContextHooks';
import { FilterContext } from '../FilterContext';
import { MandatoryAreaSearchState } from '../types';
import { MasOverlay } from './MasOverlay';
import useGoogleAutocomplete from '../../hooks/useGoogleAutocomplete';
import { useGoogleConsent } from '@oneaudi/two-click';
export const SECTION = 'section';
export const EQSECTION = 'EquipmentSection';
export const ANCHOR_MOBILE = 'AnchorMobile';
export const ANCHOR_DESKTOP = 'AnchorDesktop';
export const OVERLAY_ANCHOR_DESKTOP = 'OverlayAnchorDesktop';
export const TARGET_DESKTOP = 'TargetDesktop';
const MANDATORY_AREA_SEARCH_TIMEOUT = 1000;
export const Filter = ({ allConfiguredFilterCategories, equipmentFilterProperlyFormatted, }) => {
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    const storeService = useServicesContext().getAdditionalService('audi-stockcars-store-service');
    const configurationService = useServicesContext().getAdditionalService('vtp-configuration-service');
    const context = React.useContext(FilterContext);
    const { formattedCheckboxFilterDataFromSCS, modelFilterData, configuredFilters, isFilterOverlayOpened, setIsFilterOverlayOpened, newPickedContentSection, setNewPickedContentSection, } = context;
    const [libraryLoaded, setLibraryLoaded] = useState(false);
    const { favouritesPagePathname, locationFilterConfig_mapsAuthQueryParams } = audiContentService.getContent().fields;
    useEffect(() => {
        const initializeGoogleLibrary = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const loaded = yield useGoogleAutocomplete(locationFilterConfig_mapsAuthQueryParams);
                if (loaded)
                    setLibraryLoaded(true);
            }
            catch (error) {
                console.error('Error loading Google Maps library:', error);
            }
        });
        initializeGoogleLibrary();
    }, [locationFilterConfig_mapsAuthQueryParams]);
    const { getConfiguration, getEnvironmentConfig } = useServicesContext();
    const scsUrlParts = getScsUrlPartsFromConfiguration(undefined, getConfiguration(), getEnvironmentConfig());
    const isOnFavouritesPage = typeof window !== 'undefined' &&
        window.location.pathname.toString().includes(favouritesPagePathname);
    const layerManager = useServicesContext().getAdditionalService('layer-manager');
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const localeService = useServicesContext().getAdditionalService('gfa:locale-service');
    const locale = `${localeService.language}-${localeService.countryCode}`;
    const { isUsedCarMkt } = useScsUrlParts();
    const svd = useSvd();
    const dispatch = useDispatch();
    const consent = useGoogleConsent();
    const persistedFilters = useSelector((state) => SELECTORS.FILTERS.getPersistedFiltersMaps(state));
    const totalResults = useSelector((state) => SELECTORS.VEHICLES.getTotalCountState(state));
    const handleNavigationItemClick = (section) => {
        openOverlay();
        setNewPickedContentSection(section);
    };
    const localStorageLocationSearchInputKey = `VTP_${scsUrlParts.marketPath}_mandatoryLocationSearch--input`;
    const localStorage = new ExpiringStorage(localStorageLocationSearchInputKey, 30);
    const MASinputDataFromLocalStorage = localStorage.readEntry();
    const [mandatoryAreaSearchState, setMandatoryAreaSearchState] = useState(configurationService.getConfiguration().enableMandatoryAreaSearch
        ? MandatoryAreaSearchState.ENABLED
        : MandatoryAreaSearchState.DISABLED);
    const [googleCookieConsent] = useState(configurationService.getConfiguration().enableGoogleCookieConsent);
    const filterData = useFilterData();
    const [newPrevAppliedPresetsValues, setNewPrevAppliedPresetsValues] = useState([]);
    const [focusLayerNode, setFocusLayerNode] = useState();
    const [focusLayer, setFocusLayer] = useState();
    const focusLayerRef = React.useCallback((node) => {
        setFocusLayerNode(node);
    }, [setFocusLayerNode]);
    const openOverlay = () => {
        const layer = layerManager.openFullscreenLayer(() => React.createElement("div", { ref: focusLayerRef }), {}, {
            userCloseable: true,
            onClose: () => closeOverlay(),
        });
        setFocusLayer(layer);
        setIsFilterOverlayOpened(true);
    };
    const closeOverlay = () => {
        focusLayer === null || focusLayer === void 0 ? void 0 : focusLayer.close();
        setIsFilterOverlayOpened(false);
    };
    const [mandatoryAreaSearchModalNode, setMandatoryAreaSearchModalNode] = useState();
    const [mandatoryAreaSearchModal, setMandatoryAreaSearchModal] = useState();
    const mandatoryAreaSearchModalRef = React.useCallback((node) => {
        setMandatoryAreaSearchModalNode(node);
    }, [setMandatoryAreaSearchModalNode]);
    const openMandatoryAreaSearchModal = () => {
        const modal = layerManager.openFocusLayer(() => React.createElement("div", { ref: mandatoryAreaSearchModalRef }), {}, {
            size: "A",
            userCloseable: false,
        });
        setMandatoryAreaSearchModal(modal);
    };
    const closeMandatoryAreaSearchModal = () => {
        mandatoryAreaSearchModal === null || mandatoryAreaSearchModal === void 0 ? void 0 : mandatoryAreaSearchModal.close();
        setMandatoryAreaSearchState(MandatoryAreaSearchState.LOCATION_SET);
        dispatch(ACTIONS.UI.setLoader({ state: false, feature: 'tiles' }));
    };
    useEffect(() => {
        if ((googleCookieConsent &&
            !consent &&
            mandatoryAreaSearchState === MandatoryAreaSearchState.ENABLED) ||
            (!googleCookieConsent &&
                mandatoryAreaSearchState === MandatoryAreaSearchState.ENABLED &&
                !MASinputDataFromLocalStorage)) {
            setTimeout(openMandatoryAreaSearchModal, MANDATORY_AREA_SEARCH_TIMEOUT);
            dispatch(ACTIONS.UI.setLoader({ state: true, feature: 'tiles' }));
        }
        if (mandatoryAreaSearchState === MandatoryAreaSearchState.ENABLED &&
            MASinputDataFromLocalStorage) {
            setMandatoryAreaSearchState(MandatoryAreaSearchState.LOCATION_SET);
        }
    }, []);
    useSelector((state) => {
        const oldPrevAppliedPresets = SELECTORS.FILTERS.getPresetFiltersMap(state);
        [...oldPrevAppliedPresets.values()].map((value) => __awaiter(void 0, void 0, void 0, function* () {
            if (value.values &&
                !areArraysEqual(value.values, newPrevAppliedPresetsValues) &&
                mandatoryAreaSearchState === MandatoryAreaSearchState.DISABLED) {
                const allActiveFiltersDataStrings = createFiltersDataString(persistedFilters);
                const allActivePresetsDataStrings = createPresetsDataString(oldPrevAppliedPresets);
                setNewPrevAppliedPresetsValues(value.values);
                const filterResponse = yield updateFiltersAndResults(svd.svd, allActiveFiltersDataStrings, allActivePresetsDataStrings, configurationService, storeService.store, dispatch, getConfiguration(), getEnvironmentConfig());
                updateFilters(filterResponse);
                return '';
            }
            return '';
        }));
    });
    useEffect(() => {
        const filterCategoriesAlphabetically = [];
        allConfiguredFilterCategories.forEach((filterCategory) => {
            filterCategoriesAlphabetically.push(filterCategory.filterCategory);
        });
        const appliedFiltersForTracking = [];
        [...persistedFilters.values()].forEach((filter) => {
            const appliedFilterTrackingObj = { id: filter.id, values: [] };
            if (filter.values) {
                appliedFilterTrackingObj.values = filter.values;
            }
            appliedFiltersForTracking.push(appliedFilterTrackingObj);
        });
        const formattedResultsCount = formatCount(totalResults, locale);
        if (trackingService) {
            trackingService.featureAppName = __FEATURE_APP_NAME__;
            trackFeatureAppReady(trackingService, filterCategoriesAlphabetically.sort(), formattedResultsCount, appliedFiltersForTracking, isUsedCarMkt);
        }
    }, []);
    const appendMasPortal = () => {
        return (React.createElement(React.Fragment, null, mandatoryAreaSearchModalNode &&
            libraryLoaded &&
            ReactDOM.createPortal(React.createElement(ThemeProvider, { theme: audiLightTheme },
                React.createElement(MasOverlay, { closeMasOverlay: closeMandatoryAreaSearchModal, libraryLoaded: libraryLoaded, googleCookieConsent: googleCookieConsent })), mandatoryAreaSearchModalNode)));
    };
    if (!filterData || !formattedCheckboxFilterDataFromSCS) {
        return null;
    }
    if (isOnFavouritesPage) {
        return (React.createElement(React.Fragment, null,
            React.createElement(FilterContainer, null,
                React.createElement(OptionsToolbar, { mandatoryAreaSearchState: mandatoryAreaSearchState, openMandatoryAreaSearchModal: openMandatoryAreaSearchModal })),
            appendMasPortal()));
    }
    return (React.createElement(FilterContainer, { role: "region", "aria-labelledby": "filter-headline-desktop" },
        React.createElement(OptionsToolbar, { mandatoryAreaSearchState: mandatoryAreaSearchState, openMandatoryAreaSearchModal: openMandatoryAreaSearchModal }),
        React.createElement(FilterNavigation, { configuredFilters: configuredFilters, isFilterOverlayOpened: isFilterOverlayOpened, handleNavigationItemClick: handleNavigationItemClick, openOverlay: openOverlay, mandatoryAreaSearchState: mandatoryAreaSearchState }),
        React.createElement(ThemeProvider, { theme: audiLightTheme },
            React.createElement(ChipsList, { configuredFilters: configuredFilters })),
        React.createElement(React.Fragment, null, focusLayerNode &&
            ReactDOM.createPortal(React.createElement(ThemeProvider, { theme: audiDarkTheme },
                React.createElement(FilterOverlay, { "data-audi-core-tracking-exclude": true, "data-audi-core-tracking-include": "false", configuredFilters: configuredFilters, formattedCheckboxFilterDataFromSCS: formattedCheckboxFilterDataFromSCS, equipmentFilterCategories: equipmentFilterProperlyFormatted, isFilterOverlayOpened: isFilterOverlayOpened, modelFilterData: modelFilterData, newPickedContentSection: newPickedContentSection, closeOverlay: closeOverlay })), focusLayerNode)),
        appendMasPortal()));
};
export default Filter;
