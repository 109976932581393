import React, { useEffect, useState } from 'react';
import { Select, Text } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { useSelector } from 'react-redux';
import { SELECTORS } from '@oneaudi/stck-store';
import { rangeIncrements } from './RangeIncrements';
import { formatCount } from '../../../utils';
import { useServicesContext } from '../../../context';
import { calculateMinimumScopeValue, calculateMaximumScopeValue, getIncrementDataValues, getIncrementDataValuesForMileage, getIncrementForFinanceAmount, } from '../utils/utils';
import { RangeFilterContainer, OptionsContainer, Option, Spacing, StyledHeadingContainer, } from '../styles/RangeFilter.styles';
import InfoButton from './InfoButton';
import { useApplyFilters, useRangeScope } from '../FilterContextHooks';
export const RangeFilter = ({ filterCategory, filterGroup, layoutWidth, visibleIn, isPlpQuickFilters, }) => {
    const applyFilters = useApplyFilters();
    const rangeScope = useRangeScope();
    const localeService = useServicesContext().getAdditionalService('gfa:locale-service');
    const locale = `${localeService.language}-${localeService.countryCode}`;
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    const { powerUnit, mileageUnit } = audiContentService.getContent().fields;
    const filterGroupName = filterGroup.endsWith('.all')
        ? filterGroup.substring(0, filterGroup.length - 4)
        : filterGroup;
    const rangeFilterGroupLabel = useI18n({
        id: `nemo.sc.filtergroups.${filterGroupName}`,
        defaultMessage: '',
    });
    const rangeMinLabel = useI18n({
        id: `nemo.sc.filtergroups.${filterGroupName}.rangeMinLabel`,
        defaultMessage: 'from',
    });
    const rangeMaxLabel = useI18n({
        id: `nemo.sc.filtergroups.${filterGroupName}.rangeMaxLabel`,
        defaultMessage: 'to',
    });
    const rangeMinDefaultValue = useI18n({
        id: `nemo.sc.filtergroups.rangeMinDefaultValue`,
        defaultMessage: 'min',
    });
    const rangeMaxDefaultValue = useI18n({
        id: `nemo.sc.filtergroups.rangeMaxDefaultValue`,
        defaultMessage: 'max',
    });
    const rangePattern = useI18n({
        id: `nemo.sc.filtergroups.${filterGroupName}.rangepattern`,
        defaultMessage: '',
    });
    const getFilterDTOfromRedux = useSelector((state) => {
        if (!SELECTORS.UI.getLoadingState(state)) {
            return SELECTORS.FILTERS.getFilterDTOsMap(state).get(filterGroup);
        }
        return '';
    });
    const minDefaultValue = {
        dataValue: rangeMinDefaultValue,
        dataOriginalValue: 0,
        disabled: false,
    };
    const maxDefaultValue = {
        dataValue: rangeMaxDefaultValue,
        dataOriginalValue: Infinity,
        disabled: false,
    };
    const [availableMinDataValues, setAvailableMinDataValues] = useState([
        minDefaultValue,
    ]);
    const [availableMaxDataValues, setAvailableMaxDataValues] = useState([]);
    const [minValue, setMinValue] = useState(String(minDefaultValue.dataOriginalValue));
    const [maxValue, setMaxValue] = useState(String(maxDefaultValue.dataOriginalValue));
    const [prevMinValue, setPrevMinValue] = useState(String(minDefaultValue.dataOriginalValue));
    const [prevMaxValue, setPrevMaxValue] = useState(String(maxDefaultValue.dataOriginalValue));
    const findRangeScope = rangeScope === null || rangeScope === void 0 ? void 0 : rangeScope.find((rangeScopeItem) => rangeScopeItem.rangeFilter === filterGroup);
    const isYearRangeType = filterGroupName === 'modelYear' ||
        filterGroupName === 'productionYear' ||
        filterGroupName === 'salesModelYear' ||
        filterGroupName === 'usedCarInitialRegistrationYear';
    const isPricesRateRangeType = filterGroupName === 'prices.rate';
    const isPowerKwRangeType = filterGroupName === 'powerKw';
    const isUsedCarMileageRangeType = filterGroupName === 'usedCarMileage';
    const isUsedNumPreviousOwnersRangeType = filterGroupName === 'usedNumPreviousOwners';
    const kwHpFactor = 1.34102;
    const kwPsFactor = 1.35962;
    const conversionFactor = powerUnit === 'hp' ? kwHpFactor : kwPsFactor;
    const rangePatternOne = '{{=it.minmax1}}';
    const rangePatternTwo = '{{=it.minmax2}}';
    if (!findRangeScope) {
        console.error(`No SCS data for configured ${filterGroup} filter`);
        return null;
    }
    const updateAvailableMinDataValues = (maxAvailValue) => {
        const updatedMinDataValues = availableMinDataValues.map((dataValue) => {
            if (dataValue.dataOriginalValue > Number(maxAvailValue)) {
                return Object.assign(Object.assign({}, dataValue), { disabled: true });
            }
            return Object.assign(Object.assign({}, dataValue), { disabled: false });
        });
        setAvailableMinDataValues(updatedMinDataValues);
    };
    const updateAvailableMaxDataValues = (minAvailValue) => {
        const updatedMaxDataValues = availableMaxDataValues.map((dataValue) => {
            if (dataValue.dataOriginalValue < Number(minAvailValue)) {
                return Object.assign(Object.assign({}, dataValue), { disabled: true });
            }
            return Object.assign(Object.assign({}, dataValue), { disabled: false });
        });
        setAvailableMaxDataValues(updatedMaxDataValues);
    };
    useEffect(() => {
        var _a, _b;
        let minScopeValue = (_a = findRangeScope === null || findRangeScope === void 0 ? void 0 : findRangeScope.rangeScope) === null || _a === void 0 ? void 0 : _a.min;
        let maxScopeValue = (_b = findRangeScope === null || findRangeScope === void 0 ? void 0 : findRangeScope.rangeScope) === null || _b === void 0 ? void 0 : _b.max;
        let incrementScope = {
            rangeFilter: '',
            increment: 0,
        };
        if (filterGroupName === 'prices.retail' || filterGroupName === 'financeAmount') {
            incrementScope = {
                rangeFilter: filterGroupName,
                increment: getIncrementForFinanceAmount(minScopeValue, maxScopeValue),
            };
        }
        else {
            incrementScope = rangeIncrements.find((scope) => {
                if (scope.rangeFilter === filterGroupName) {
                    return scope.rangeFilter === filterGroupName;
                }
                return scope.rangeFilter === 'default';
            });
        }
        if (!isYearRangeType && !isUsedCarMileageRangeType && !isUsedNumPreviousOwnersRangeType) {
            minScopeValue = Math.round(calculateMinimumScopeValue(minScopeValue, incrementScope.increment));
            maxScopeValue = Math.round(calculateMaximumScopeValue(maxScopeValue, incrementScope.increment));
        }
        let dataOriginalValues;
        if (isUsedCarMileageRangeType) {
            dataOriginalValues = getIncrementDataValuesForMileage(minScopeValue, maxScopeValue, incrementScope.increment);
        }
        else {
            dataOriginalValues = getIncrementDataValues(minScopeValue, maxScopeValue, incrementScope.increment);
        }
        let dataValues;
        switch (true) {
            case isYearRangeType:
                dataValues = dataOriginalValues.map(String);
                break;
            case isUsedCarMileageRangeType:
                dataValues = [
                    ...dataOriginalValues.map((dataOriginalValue) => {
                        return `${formatCount(dataOriginalValue, locale).toString()} ${mileageUnit}`;
                    }),
                ];
                break;
            case isPowerKwRangeType:
                dataValues = [
                    ...dataOriginalValues.map((dataOriginalValue) => {
                        let powerPatternString = rangePattern.replace(rangePatternOne, dataOriginalValue.toString());
                        powerPatternString = powerPatternString.replace(rangePatternTwo, Math.round(dataOriginalValue * conversionFactor).toString());
                        return powerPatternString;
                    }),
                ];
                break;
            default:
                dataValues = [
                    ...dataOriginalValues.map((dataOriginalValue) => {
                        return formatCount(dataOriginalValue, locale).toString();
                    }),
                ];
        }
        if (dataOriginalValues.length > 0) {
            dataOriginalValues.map((originalValue, index) => {
                return setAvailableMinDataValues((prevArray) => [
                    ...prevArray,
                    {
                        dataValue: dataValues && dataValues[index],
                        dataOriginalValue: originalValue,
                        disabled: false,
                    },
                ]);
            });
        }
        dataOriginalValues === null || dataOriginalValues === void 0 ? void 0 : dataOriginalValues.map((originalValue, index) => {
            return setAvailableMaxDataValues((prevArray) => [
                ...prevArray,
                {
                    dataValue: dataValues && dataValues[index],
                    dataOriginalValue: originalValue,
                    disabled: false,
                },
            ]);
        });
        setAvailableMaxDataValues((prevArray) => [...prevArray, maxDefaultValue]);
    }, []);
    useEffect(() => {
        if (getFilterDTOfromRedux) {
            let minValueFromRedux;
            let maxValueFromRedux;
            if (getFilterDTOfromRedux === null || getFilterDTOfromRedux === void 0 ? void 0 : getFilterDTOfromRedux.values) {
                minValueFromRedux =
                    (getFilterDTOfromRedux === null || getFilterDTOfromRedux === void 0 ? void 0 : getFilterDTOfromRedux.values[0]) === '' ? '0' : getFilterDTOfromRedux === null || getFilterDTOfromRedux === void 0 ? void 0 : getFilterDTOfromRedux.values[0];
                maxValueFromRedux =
                    (getFilterDTOfromRedux === null || getFilterDTOfromRedux === void 0 ? void 0 : getFilterDTOfromRedux.values[1]) === '' ? 'Infinity' : getFilterDTOfromRedux === null || getFilterDTOfromRedux === void 0 ? void 0 : getFilterDTOfromRedux.values[1];
            }
            else {
                minValueFromRedux = '0';
                maxValueFromRedux = 'Infinity';
            }
            setMinValue(minValueFromRedux);
            setMaxValue(maxValueFromRedux);
            setPrevMinValue(minValueFromRedux);
            setPrevMaxValue(maxValueFromRedux);
        }
    }, [getFilterDTOfromRedux]);
    useEffect(() => {
        if (availableMaxDataValues.length > 0) {
            updateAvailableMaxDataValues(minValue);
        }
    }, [minValue]);
    useEffect(() => {
        if (availableMinDataValues.length > 1) {
            updateAvailableMinDataValues(maxValue);
        }
    }, [maxValue]);
    const handleChangeMin = (e) => {
        const filterDTO = {
            id: filterGroup,
            active: e.target.value !== '0' || maxValue !== 'Infinity',
            values: [
                e.target.value !== '0' ? e.target.value : '',
                maxValue !== 'Infinity' ? maxValue : '',
            ],
        };
        const currentFilter = {
            id: filterGroup,
            values: [
                e.target.value !== '0' ? e.target.value : '',
                maxValue !== 'Infinity' ? maxValue : '',
            ],
        };
        let trackingEventName = '';
        if (minValue === '0' && maxValue === 'Infinity') {
            trackingEventName = 'vtp filter - add filter';
        }
        else if (e.target.value === '0' && maxValue === 'Infinity') {
            trackingEventName = 'vtp filter - remove filter';
            currentFilter.values = [prevMinValue, ''];
            setPrevMinValue(e.target.value);
        }
        else if ((maxValue !== 'Infinity' && e.target.value === '0') || e.target.value !== '0') {
            trackingEventName = 'vtp filter - change filter';
        }
        const trackingData = {
            trackingEventName,
            trackingLabel: '',
            trackingValue: filterCategory,
            changedFilter: [currentFilter],
        };
        setMinValue(e.target.value);
        applyFilters(filterDTO, trackingData);
    };
    const handleChangeMax = (e) => {
        const filterDTO = {
            id: filterGroup,
            active: minValue !== '0' || e.target.value !== 'Infinity',
            values: [
                minValue !== '0' ? minValue : '',
                e.target.value !== 'Infinity' ? e.target.value : '',
            ],
        };
        const currentFilter = {
            id: filterGroup,
            values: [
                minValue !== '0' ? minValue : '',
                e.target.value !== 'Infinity' ? e.target.value : '',
            ],
        };
        let trackingEventName = '';
        if (minValue === '0' && maxValue === 'Infinity') {
            trackingEventName = 'vtp filter - add filter';
        }
        else if (minValue === '0' && e.target.value === 'Infinity') {
            trackingEventName = 'vtp filter - remove filter';
            currentFilter.values = ['', prevMaxValue];
            setPrevMaxValue(e.target.value);
        }
        else if ((minValue !== '0' && e.target.value === 'Infinity') ||
            e.target.value !== 'Infinity') {
            trackingEventName = 'vtp filter - change filter';
        }
        if (isUsedNumPreviousOwnersRangeType && isPricesRateRangeType) {
            if (maxValue === 'Infinity') {
                trackingEventName = 'vtp filter - add filter';
            }
            else if (e.target.value === 'Infinity') {
                trackingEventName = 'vtp filter - remove filter';
                currentFilter.values = ['', prevMaxValue];
                setPrevMaxValue(e.target.value);
            }
            else if (e.target.value !== 'Infinity') {
                trackingEventName = 'vtp filter - change filter';
            }
        }
        const trackingData = {
            trackingEventName,
            trackingLabel: '',
            trackingValue: filterCategory,
            changedFilter: [currentFilter],
        };
        setMaxValue(e.target.value);
        applyFilters(filterDTO, trackingData);
    };
    return (React.createElement(RangeFilterContainer, { layoutWidth: layoutWidth, isPlpQuickFilters: isPlpQuickFilters },
        React.createElement(StyledHeadingContainer, { layoutWidth: layoutWidth, isPlpQuickFilters: isPlpQuickFilters },
            React.createElement(Text, { as: "h4", variant: isPlpQuickFilters ? 'copy2' : 'order3' }, rangeFilterGroupLabel),
            React.createElement(InfoButton, { filterGroupIdOrFilterId: filterGroup, filterOrGroupText: rangeFilterGroupLabel, filterCategory: filterCategory })),
        React.createElement(OptionsContainer, { layoutWidth: layoutWidth },
            !isUsedNumPreviousOwnersRangeType && !isPricesRateRangeType && (React.createElement(React.Fragment, null,
                React.createElement(Option, null,
                    React.createElement(Select, { inputId: `select_min_${filterGroup}_${visibleIn}`, label: rangeMinLabel, onChange: handleChangeMin, value: minValue, hideLabelOptional: true, required: true }, availableMinDataValues.map((availableMinDataValue, index) => {
                        return (React.createElement("option", { value: availableMinDataValue.dataOriginalValue, disabled: availableMinDataValue.disabled, key: index, "data-test-id": "option" }, availableMinDataValue.dataValue));
                    }))),
                React.createElement(Spacing, null))),
            React.createElement(Option, { style: {
                    width: isUsedNumPreviousOwnersRangeType || isPricesRateRangeType ? '50%' : '100%',
                    flex: isUsedNumPreviousOwnersRangeType || isPricesRateRangeType ? 'unset' : '1 1 0px',
                } },
                React.createElement(Select, { inputId: `select_max_${filterGroup}_${visibleIn}`, label: rangeMaxLabel, onChange: handleChangeMax, value: maxValue, required: true, hideLabelOptional: true }, availableMaxDataValues.map((availableMaxDataValue, index) => {
                    return (React.createElement("option", { value: availableMaxDataValue.dataOriginalValue, disabled: availableMaxDataValue.disabled, key: index }, availableMaxDataValue.dataValue));
                }))))));
};
export default RangeFilter;
