import ReactDOM from 'react-dom';
import React, { useState, useRef, useEffect } from 'react';
import { LocationFilterContainer } from '../styles/LocationFilter.styles';
import { LocationFilterHeader } from './LocationFilterHeader';
import { CookieConsentControl } from './CookieConsentControl';
import { LocationFilterBody } from './LocationFilterBody';
import { ConsentModal, useGoogleConsent } from '@oneaudi/two-click';
import { useClearAllDealerFilters } from '../FilterContextHooks';
import { useI18n } from '@oneaudi/i18n-context';
import { useServicesContext } from '../../../context';
import { getScsUrlPartsFromConfiguration } from '../../../hooks';
import { ExpiringStorage } from '@oneaudi/stck-store';
import { updateLocationSearchInputLocalStorage, setAddressFromLatLngGeocoder, initializeInitialStateFromLocalStorage, setLocationFilterToDefaultState, } from '../utils/locationFilterHelpers';
import { FilterContext } from '../FilterContext';
export const LocationFilter = ({ visibleIn, overlayRef }) => {
    var _a, _b;
    const layerManager = useServicesContext().getAdditionalService('layer-manager');
    const localeService = useServicesContext().getAdditionalService('gfa:locale-service');
    const { countryCode } = localeService;
    const countryISO = countryCode.toUpperCase();
    const { getConfiguration, getEnvironmentConfig } = useServicesContext();
    const scsUrlParts = getScsUrlPartsFromConfiguration(undefined, getConfiguration(), getEnvironmentConfig());
    const configurationFA = useServicesContext().getAdditionalService('vtp-configuration-service');
    const enabledMandatoryAreaSearch = ((_a = configurationFA === null || configurationFA === void 0 ? void 0 : configurationFA.getConfiguration()) === null || _a === void 0 ? void 0 : _a.enableMandatoryAreaSearch) || false;
    const localStorageLocationSearchInputKey = enabledMandatoryAreaSearch
        ? `VTP_${scsUrlParts.marketPath}_mandatoryLocationSearch--input`
        : `VTP_${scsUrlParts.marketPath}_locationSearch--input`;
    const context = React.useContext(FilterContext);
    const { setWholeMarketDealersFetch, radius, setRadius, setSearchedCoords } = context;
    const [googleCookieConsent] = useState(getConfiguration().enableGoogleCookieConsent);
    const consent = !googleCookieConsent ? true : useGoogleConsent();
    const clearAllDealerFilters = useClearAllDealerFilters();
    const [twoClickOverlayOpened, setIsTwoClickOverlayOpened] = useState(false);
    const [map, setMap] = useState();
    const inputRef = useRef(null);
    const changedByLocateMe = useRef(false);
    const handleLocateMeClick = () => {
        changedByLocateMe.current = true;
        const options = {
            enableHighAccuracy: true,
            timeout: 10000,
        };
        const successCallback = (position) => {
            if (map) {
                setAddressFromLatLngGeocoder(position, inputRef, localStorageLocationSearchInputKey);
            }
            context.setSearchedCoords({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            });
        };
        const errorCallback = (error) => {
            console.error(error);
        };
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
    };
    const [focusLayerNode, setFocusLayerNode] = useState();
    const [focusLayer, setFocusLayer] = useState();
    const focusLayerRef = React.useCallback((node) => {
        setFocusLayerNode(node);
    }, [setFocusLayerNode]);
    const openConsentOverlay = () => {
        const layer = layerManager.openFocusLayer(() => React.createElement("div", { ref: focusLayerRef }), {}, {
            userCloseable: true,
            size: "B",
        });
        setFocusLayer(layer);
        setIsTwoClickOverlayOpened(true);
    };
    const mapConsentButtonText = useI18n({
        id: `nemo.sc.filter.locationfilter.cookieConsentRequestDealerList.Button`,
        defaultMessage: '',
    });
    const consentModalHeadline = useI18n({
        id: `google-maps-two-click-consent.headline`,
        defaultMessage: '',
    });
    const consentModalBody = useI18n({
        id: `google-maps-two-click-consent.copyText`,
        defaultMessage: '',
    });
    const consentModalToggle = useI18n({
        id: `google-maps-two-click-consent.toggleLabel`,
        defaultMessage: '',
    });
    const marketInitialSearchInput = useI18n({
        id: `nemo.sc.filter.locationfilter.marketInitialSearchInput`,
        defaultMessage: countryISO,
    });
    useEffect(() => {
        if (map && inputRef.current) {
            const localStorage = new ExpiringStorage(localStorageLocationSearchInputKey, 30);
            const inputDataFromLocalStorage = localStorage.readEntry();
            if (inputDataFromLocalStorage) {
                setWholeMarketDealersFetch(false);
                initializeInitialStateFromLocalStorage(inputRef, inputDataFromLocalStorage, setRadius, setSearchedCoords);
            }
            else {
                setWholeMarketDealersFetch(true);
            }
        }
    }, [map, inputRef.current]);
    useEffect(() => {
        if (radius)
            updateLocationSearchInputLocalStorage(localStorageLocationSearchInputKey, 'radius', radius);
    }, [radius]);
    useEffect(() => {
        var _a;
        if (inputRef.current && ((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.value) !== marketInitialSearchInput) {
            updateLocationSearchInputLocalStorage(localStorageLocationSearchInputKey, 'inputName', inputRef.current.value);
        }
    }, [(_b = inputRef.current) === null || _b === void 0 ? void 0 : _b.value]);
    useEffect(() => {
        if (consent) {
            focusLayer === null || focusLayer === void 0 ? void 0 : focusLayer.close();
            setIsTwoClickOverlayOpened(false);
        }
        else {
            if (inputRef.current) {
                inputRef.current.value = marketInitialSearchInput;
            }
            setLocationFilterToDefaultState(getConfiguration(), getEnvironmentConfig(), clearAllDealerFilters, context, inputRef);
        }
    }, [consent]);
    return (React.createElement(LocationFilterContainer, null,
        React.createElement(LocationFilterHeader, { consent: consent, inputRef: inputRef, mapConsentButtonText: mapConsentButtonText, openConsentOverlay: openConsentOverlay, setIsTwoClickOverlayOpened: setIsTwoClickOverlayOpened, handleLocateMeClick: handleLocateMeClick, overlayRef: overlayRef }),
        React.createElement(React.Fragment, null, twoClickOverlayOpened &&
            focusLayerNode &&
            ReactDOM.createPortal(React.createElement(ConsentModal, { theme: "light", headerText: consentModalHeadline, bodyText: consentModalBody, toggleText: consentModalToggle, showCloseButton: true, showRedirectButton: false, closeButtonCallback: () => {
                    focusLayer === null || focusLayer === void 0 ? void 0 : focusLayer.close();
                    setIsTwoClickOverlayOpened(false);
                }, twoClickStyle: {
                    position: 'relative',
                    padding: 0,
                    top: 'unset',
                    transform: 'unset',
                    maxHeight: 'unset',
                    width: 'unset',
                    left: 'unset',
                }, twoClickCancelCtaStyle: {
                    display: 'none',
                } }), focusLayerNode)),
        React.createElement(LocationFilterBody, { consent: consent, changedByLocateMe: changedByLocateMe, inputRef: inputRef, map: map, visibleIn: visibleIn, mapConsentButtonText: mapConsentButtonText, openConsentOverlay: openConsentOverlay, setIsTwoClickOverlayOpened: setIsTwoClickOverlayOpened, setMap: setMap }),
        consent && googleCookieConsent && React.createElement(CookieConsentControl, { setMap: setMap })));
};
export default LocationFilter;
